import * as React from "react";

import { Template, FloatingReadMore, Meta } from "common/layout";
import { BlogSection } from "modules/blog";

const BlogOverviewPage: React.FC = () => {
  return (
    <Template>
      <Meta
        customTitleTemplate="NOWATCH Blog"
      />
      <FloatingReadMore animateScroll />
      <BlogSection />
    </Template>
  );
};

export default BlogOverviewPage;
